import {createSlice} from '@reduxjs/toolkit';

const localStorageItemName = 'user';
const initialState = {
  user:
    typeof window !== 'undefined'
      ? JSON.parse(localStorage.getItem(localStorageItemName)) ?? false
      : false,
};

export const auth = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    login: (state, action) => {
      if (typeof window !== 'undefined') {
        localStorage.setItem(
          localStorageItemName,
          JSON.stringify(action.payload),
        );
      }
      state.user = action.payload;
    },
    logout: (state) => {
      if (typeof window !== 'undefined') {
        localStorage.removeItem(localStorageItemName);
      }

      state.user = false;
    },
  },
});

export const {login, logout} = auth.actions;

export default auth.reducer;
